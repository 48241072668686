import logo from "./logo.png";
import promo from "./promo.svg";
import "./App.css";

import React from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Box,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { Instagram, Email } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { motion, AnimatePresence } from "framer-motion";

import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./Firebase";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fffbf5",
        padding: "20px",
        textAlign: "center",
        width: "100%",
      }}
    >
      <Typography
        variant="body1"
        fontFamily={"DM Sans"}
        color={"#333"}
        fontWeight={600}
      >
        &copy; {currentYear} NovelNet
      </Typography>

      <Box pl={2}>
        <IconButton
          component={Link}
          href="https://www.instagram.com/novelnetapp/"
          target="_blank"
          color="inherit"
        >
          <Instagram />
        </IconButton>
        <IconButton
          component={Link}
          href="mailto:info@novelnet.co.uk"
          color="inherit"
        >
          <Email />
        </IconButton>
      </Box>
    </Box>
  );
}

function App() {
  const [email, setEmail] = React.useState("");
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [error, setError] = React.useState(false);

  const joinWaitlist = async () => {
    const lowercaseEmail = email.toLowerCase();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!lowercaseEmail.trim() || !emailRegex.test(lowercaseEmail)) {
      setError("Valid email is required.");
      return;
    }
    try {
      const docRef = doc(db, "WaitingList", lowercaseEmail);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        // Email doesn't exist, add it to the collection
        await setDoc(docRef, {
          email: lowercaseEmail,
          timestamp: serverTimestamp(),
        });
        setEmail("");
        setShowSuccessMessage(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        setEmail("");
        setError("You've already joined the waiting list");
      }
    } catch (e) {
      console.log(e);
    }
    setEmail("");
    setError("Sorry, please try again");
  };

  return (
    <Grid
      backgroundColor=" #fffbf5"
      container
      columns={{ sm: 1, md: 2 }}
      rowSpacing={{ xs: 20, md: 0 }}
      paddingTop={{ xs: 10, md: 0 }}
      alignItems={"center"}
      justifyContent={{ xs: "center", md: "space-between" }}
      alignSelf={"center"}
      overflow={"hidden"}
    >
      <Grid
        item
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: "45%",
          minWidth: 350,
        }}
        paddingLeft={{ xs: 0, md: 10 }}
      >
        <AnimatePresence mode="wait">
          {!showSuccessMessage ? (
            <motion.div
              key="form"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="form-container"
            >
              <Box width={{ xs: "30vw", sm: "20vw", md: "15vw", lg: "10vw" }}>
                <img
                  src={logo}
                  className="App-logo"
                  alt="logo"
                  style={{
                    width: "100%",
                    resizeMode: "contain",
                    height: "10%",
                  }}
                />
              </Box>
              <Typography
                variant="h2"
                fontWeight={600}
                pb={{ xs: 2, md: 6 }}
                fontSize={{ xs: 40, md: 60 }}
                fontFamily={"DM Sans"}
                mt={14}
              >
                Be the first to discover a new way to read
              </Typography>
              <Typography
                variant="h6"
                fontSize={{ xs: 15, md: 18 }}
                pb={{ xs: 2, md: 6 }}
                fontFamily={"Open Sans"}
              >
                Connect with stories, authors, and readers like never before.
                Stay updated on Novelnet’s launch and be part of a new era in
                reading.
              </Typography>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                open={error}
                autoHideDuration={6000}
                width={{ xs: "100vw", md: "300" }}
                onClose={() => {
                  setError(false);
                }}
              >
                <Alert
                  onClose={() => {
                    setError(false);
                  }}
                  severity="error"
                  sx={{ bgcolor: "#fffbf5", width: "100%" }}
                  variant="outlined"
                >
                  {error}
                </Alert>
              </Snackbar>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginRight: 8 }}>
                      <IconButton edge="end" onClick={joinWaitlist}>
                        <AutoAwesomeIcon className="colorCycle" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    fontFamily: "DM Sans", // Use "DM Sans" if you want it to match the header
                    fontSize: 15, // Adjust as needed
                    fontWeight: 600, // Adjust as needed
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "Open Sans",
                    fontSize: 15, // Adjust as needed
                    fontWeight: 400, // Adjust as needed
                    color: "#C4C1BC",
                  },
                }}
              />
              <Button
                variant="contained"
                className="buttonColorCycle"
                disableElevation
                style={{
                  color: "black",
                  marginTop: 20,
                }}
                fullWidth
                endIcon={<ArrowForwardIcon />}
                onClick={joinWaitlist}
              >
                <Typography
                  variant="h6"
                  fontSize={16}
                  style={{ textTransform: "none" }}
                  fontWeight={600}
                  fontFamily={"DM Sans"}
                >
                  {"Join the waitlist for early access"}
                </Typography>
              </Button>
            </motion.div>
          ) : (
            <motion.div
              key="success"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ type: "spring", stiffness: 100, duration: 2 }}
              exit={{ opacity: 0 }}
              className="success-message-container"
            >
              <AutoAwesomeIcon className="colorCycle" />
              <Typography
                variant="h6"
                fontSize={16}
                style={{ textTransform: "none" }}
                fontWeight={600}
                fontFamily={"DM Sans"}
                mt={2}
              >
                You're in! Get ready to turn the page on a new reading
                experience.
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
        <Box display={{ xs: "none", sm: "block" }}>
          <Footer />
        </Box>
      </Grid>
      <Grid item>
        <div className="gradientBackground">
          <img
            src={promo}
            className="App-promo"
            alt="promo"
            style={{ height: "80%", resizeMode: "contain" }}
          />
        </div>
      </Grid>
      <Box display={{ xs: "block", sm: "none" }}>
        <Footer />
      </Box>
    </Grid>
  );
}

export default App;
